import { useSelector } from "react-redux";
import { Box } from "../../components/StyledComponent";
import Showcase from "./Showcase/Showcase";
import Videos from "./Showcase/Videos";
import Tour from "./Showcase/Tour";
import Music from "./Showcase/Music";
import Merch from "./Showcase/Merch";
import Contact from "./Showcase/Contact";
import Instagram from "./Showcase/Instagram";
import Footer from "./Showcase/Footer";
import Filler from "./Filler";

// import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";

export default function Home() {
  return (
    <Box className="text-white h-auto w-full flex flex-col justify-center items-center">
      {/* <ScrollToHashElement behavior="smooth" inline="center" block="center" /> */}
      <Showcase />
      <Filler />
      <Videos />
      <Tour />
      <Music />
      <Merch />
      <Contact />
      <Instagram />
    </Box>
  );
}
