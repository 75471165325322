export enum RoutePattern {
  HOME = "home",
  TOUR = "tour",
  ABOUT = "about",
  MUSIC = "music",
  VIDEO = "video",
  SHOP = "shop",
  CONTACT ="contact",
  INSTAGRAM = "instagram",
}
