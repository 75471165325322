import { faL } from "@fortawesome/free-solid-svg-icons";
import { RoutePattern } from "../../routes/RoutePattern";

export const leftMenu = [
  {
    name: RoutePattern.HOME,
    to: "/" + RoutePattern.HOME,
    isRoute: true,
    linkOut: false,
    hideMobile: false,
  },
  {
    name: RoutePattern.TOUR,
    to: RoutePattern.TOUR,
    isRoute: true,
    linkOut: false,
    hideMobile: false,
  },
  {
    name: RoutePattern.MUSIC,
    to: RoutePattern.MUSIC,
    isRoute: true,
    linkOut: false,
    hideMobile: false,
  },
];

export const rightMenu = [
  {
    name: RoutePattern.VIDEO,
    to: "https://www.youtube.com/user/itsdorothysucka",
    isRoute: false,
    linkOut: true,
    hideMobile: false,
  },
  {
    name: RoutePattern.SHOP,
    to: "https://dorothyonfire.store/",
    isRoute: false,
    linkOut: true,
    hideMobile: true,
  },
  {
    name: RoutePattern.CONTACT,
    to: RoutePattern.CONTACT,
    isRoute: true,
    linkOut: false,
    hideMobile: false,
  },
  {
    name: RoutePattern.ABOUT,
    to: RoutePattern.ABOUT,
    isRoute: false,
    linkOut: false,
    hideMobile: false,
  },
];
