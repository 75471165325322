import {
  faApple,
  faFacebookF,
  faInstagram,
  faSpotify,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faMusic } from "@fortawesome/free-solid-svg-icons";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  {
    icon: faSpotify,
    link: "https://open.spotify.com/artist/6IOvhXyk5edbA2DVaeP9Up",
  },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/dorothy/",
  },
  {
    icon: faFacebookF,
    link: "http://facebook.com/itsdorothysucka/",
  },
  {
    icon: faTwitter,
    link: "https://twitter.com/itsdorothysucka",
  },
  {
    icon: faYoutube,
    link: "https://www.youtube.com/user/itsdorothysucka",
  },
];
