import {
    Box,
  } from "../../../components/StyledComponent";
  import { useEffect, useRef, useState } from "react";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { RoutePattern } from "../../../routes/RoutePattern";
  import styled from "styled-components";
  import Button from "../../../components/Form/Button";
  import { isMobile } from "../../../constants";

  import Splide from "@splidejs/splide";
  import '@splidejs/splide/css';
  import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';
  import { Video } from '@splidejs/splide-extension-video';
  
  // import HERO_D from "../../../assets/images/video_desktop.png";
  // import HERO_M from "../../../assets/images/video_mobile.png";

  const videoList = [
    {
      videoId: "S-oVdHKr4uY",
      title: "Dorothy - Gifts From The Holy Ghost (Vevo Studio Performance)",
    },
    {
      videoId: "5zBUlhcRyWw",
      title: "Dorothy - Black Sheep",
    },
    {
      videoId: "fRs8olywhS4",
      title: "Dorothy - Black Sheep (Visualizer)",
    },
    {
      videoId: "cg2P3MH5WYs",
      title: "Dorothy - Rest In Peace (Official Music Video)",
    },
  ];

  const Iframe = styled.iframe`
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 1rem !important;
  `;

  const Videos = () => {
    useEffect(() => {
        var splide = new Splide( '.splide', {
            type  : 'fade',
            rewind: true,
            pagination: false,
            cover: true,
            classes: {
                prev  : 'splide__arrow--prev',
                next  : 'splide__arrow--next',
            },
          } );
          
          splide.mount({ Video });
    });

    return (
      <Box
        id={RoutePattern.VIDEO}
        className="relative flex flex-col justify-center items-center h-96 md:h-[40rem] 3xl:h-[50rem] w-full overflow-hidden bg-[#FEF9D7]"
      >
        {/* <Box className={`flex flex-col justify-center items-center absolute h-full w-full inset-0 bg-fixed bg-no-repeat bg-cover bg-center`} style={{backgroundImage: `url(${isMobile ? HERO_M : HERO_D})`}}>
        </Box> */}
        <Box className={`flex flex-col justify-center items-center absolute h-full w-full`}>
          <section className="splide w-full md:w-3/5 h-auto" aria-label="Splide Basic HTML Example">
              <div className="splide__track mx-10 md:mx-16">
                  <ul className="splide__list">
                      {videoList.map((item, idx) => (
                          <li key={idx} className="splide__slide bg-white text-black min-h-[11rem] md:min-h-[28rem] 3xl:min-h-[35rem]" data-splide-youtube={`https://www.youtube.com/watch?v=${item.videoId}`}>
                            <img src={`https://img.youtube.com/vi/${item.videoId}/sddefault.jpg`}></img>
                          </li>
                      ))}
                  </ul>
              </div>
          </section>
        </Box>
      </Box>
    );
  };
  
  export default Videos;
  