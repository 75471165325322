import {
    Box,
  } from "../../components/StyledComponent";
  import styled from "styled-components";
  // import { isMobile } from "../../../constants";
  
  import BGIMG from "../../assets/images/colors_bg.jpg";
  
  const Showcase = () => {
    return (
      <Box
        className="relative flex flex-col justify-center items-center h-auto w-full bg-[#BA5C12]"
      >
        <Box className="flex justify-center items-center h-auto w-full text-[#FEF9D7] text-center text-3xl md:tracking-widest font-medium my-3 md:my-5">
            {/* <img src={BGIMG} className="-z-[1] absolute object-cover w-full h-full" /> */}
            <h2 className="cursor-pointer">JOIN THE MAILING LIST & RECEIVE THE LATEST UPDATES FROM DOROTHY!</h2>
        </Box>
      </Box>
    );
  };
  
  export default Showcase;
  