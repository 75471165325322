import {
  Box,
} from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import styled from "styled-components";
import Button from "../../../components/Form/Button";
import { isMobile } from "../../../constants";

import HERO_D from "../../../assets/images/hero-desktop.png";
import HERO_M from "../../../assets/images/hero-mobile.png";

const FloatingButton = styled.div`
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
`;

const Showcase = () => {
  return (
    <Box
      id={RoutePattern.HOME}
      className="relative flex flex-col justify-center items-center h-screen w-full overflow-hidden"
    >
      <Box className={`${isMobile ? '' : 'parallax-bg '}flex flex-col justify-center items-center absolute h-full w-full inset-0 bg-fixed bg-no-repeat bg-cover bg-center`} style={{backgroundImage: `url(${isMobile ? HERO_M : HERO_D})`}}>
      </Box>

      {/* <FloatingButton className="z-50 fixed left-5 bottom-0 font-semibold bg-white text-black px-10 py-3 rounded-t cursor-pointer text-lg">
        Get 15% Off
      </FloatingButton> */}
    </Box>
  );
};

export default Showcase;
