import {
    Box,
  } from "../../../components/StyledComponent";
  import { useEffect, useRef, useState } from "react";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { RoutePattern } from "../../../routes/RoutePattern";
  import styled from "styled-components";
  import Button from "../../../components/Form/Button";
  import { SocialIcons } from "../../../components";
  import { isMobile } from "../../../constants";
  
  import BG_D from "../../../assets/images/footer_bg.jpg";
  import BG_M from "../../../assets/images/merch_mobile.jpg";
  import BANNER from "../../../assets/images/Dorothy Name cream.png";
  
  const Footer = () => {
    return (
      <Box
        className="relative flex flex-col justify-center items-center h-fit w-full"
      >
        {/* <Box className={`flex flex-col justify-center items-center absolute h-full w-full inset-0 bg-no-repeat bg-cover bg-center md:bg-top opacity-75`} style={{backgroundImage: `url(${isMobile ? BG_M : BG_D})`}}>
        </Box> */}
        <Box className={`flex flex-col justify-center items-center relative h-full w-full`}>
            <Box className="relative flex flex-col justify-center items-center my-8 md:mb-2">
                <Box className="flex flex-col md:flex-row justify-center items-center">
                    <img src={BANNER} className="w-32 md:w-[22rem] my-16 md:border-r-2 md:border-[#FEF9D7] px-0 md:pl-52 md:pr-12 py-0 md:py-3" />
                    <Box className="font-cabin text-lg mb-4 md:mb-0 text-center md:hidden text-[#FEF9D7]">
                        <a href="https://rocnation.com/dorothy/" target="_blank">DOROTHY / ROCNATION</a>
                    </Box>
                    <SocialIcons />
                    <Box className="flex justify-start items-center font-cabin md:tracking-wider text-[#FEF9D7] text-lg w-96 mb-4 md:mb-0 md:border-l-2 md:border-[#FEF9D7] px-0 md:pl-12 hidden md:flex h-16">
                        <a href="https://rocnation.com/dorothy/" target="_blank">DOROTHY / ROCNATION</a>
                    </Box>
                </Box>
                <Box className="uppercase flex flex-col font-cabin justify-center items-center text-[#FEF9D7] text-[0.7rem] tracking-widest mt-20 mb-5 md:m-0">
                    <div>&copy; {new Date().getFullYear()} ROCNATION</div>
                    {/* <div className="opacity-50 mt-1">photography by: courtney dellafiora</div> */}
                </Box>
            </Box>
        </Box>
      </Box>
    );
  };
  
  export default Footer;