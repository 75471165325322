import {
    Box,
  } from "../../../components/StyledComponent";
  import { useEffect, useRef, useState } from "react";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { RoutePattern } from "../../../routes/RoutePattern";
  import styled from "styled-components";
  import Button from "../../../components/Form/Button";
  import { isMobile } from "../../../constants";
  
  import BANNER from "../../../assets/images/about_header4.png";
  
  const About = () => {
    return (
      <Box
        className="relative flex flex-col justify-center items-center h-fit w-full pt-10"
      >
        {/* <Box className={`flex flex-col justify-center items-center absolute h-full w-full inset-0 bg-fixed bg-no-repeat bg-cover bg-center opacity-75`} style={{backgroundImage: `url(${isMobile ? BG_M : BG_D})`}}>
        </Box> */}
        <Box className={`flex flex-col justify-center items-center relative h-full w-full`}>
            <Box className="w-full md:w-3/5 relative flex flex-col justify-center items-center px-5 my-8 md:my-14">
                <img src={BANNER} className="w-auto" style={{filter: 'brightness(10%) saturate(100%) invert(93%) sepia(5%) saturate(1279%) hue-rotate(21deg) brightness(109%) contrast(99%)'}} />
                <Box className="font-cabin text-[#FEF9D7] text-sm md:text-lg text-justify mb-5">
                    Dorothy Martin’s life changed forever when she was forced to face death on her tour bus some three years ago. After her guitar technician had taken an overdose, Dorothy instinctively began praying for his survival. While he may have temporarily died, the technician was astonishingly, miraculously restored back to life. It was this moment that seemed to bring Dorothy to life too. It was this divine intervention that caused a radical and spiritual awakening in the singer, the result of which can be heard on Gifts From The Holy Ghost, Dorothy’s third studio album as front person for the pseudonymous, rock band Dorothy. 
                </Box>
                <Box className="font-cabin text-[#FEF9D7] text-sm md:text-lg text-justify mb-5">
                    With the combined powers of Keith Wallen, Jason Hook, Scott Stevens, Phil X, Trevor Lukather, Joel Hamiltonand the legendary ear of producer Chris Lord Alge, Gifts From The Holy Ghost is made from a musical palette which seems to encompass each of the musician’s influences, as well as many of the essential sounds of rock music’s history—from swampy blues to ‘90s alternative —in a way that makes the case for rock and roll itself. Not only is the genre alive, but it’s more invigorated than ever.
                </Box>
                <Box className="font-cabin text-[#FEF9D7] text-sm md:text-lg text-justify mb-5">
                    The album’s lyrics are a perfect balance of specificity and generality, so that the listener can attach their own darknesses and triumphs to the songs, while still getting a sense of Dorothy’s own. “We are all one human family.” she declares. 
                </Box>
                <Box className="font-cabin text-[#FEF9D7] text-sm md:text-lg text-justify">
                    With Gifts From The Holy Ghost, Dorothy identifies her purpose as an artist. She conquers darkness with light, numbness with feeling, disharmony with unity—all while delivering one of this year’s most fun rock & roll records.
                </Box>
            </Box>
        </Box>
      </Box>
    );
  };
  
  export default About;