import {
    Box,
  } from "../../../components/StyledComponent";
  import { useEffect, useRef, useState } from "react";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { RoutePattern } from "../../../routes/RoutePattern";
  import styled from "styled-components";
  import Button from "../../../components/Form/Button";
  import { isMobile } from "../../../constants";
  import useScriptService from "../../Hooks/useScriptService";
  
  import BG_D from "../../../assets/images/tour_desktop4.png";
  import BG_M from "../../../assets/images/tour_mobile4.png";
  import BANNER from "../../../assets/images/tour2.png";
  
  const Tour = () => {
    useScriptService({ url: "https://widget.bandsintown.com/main.min.js" });

    return (
      <Box
        id={RoutePattern.TOUR}
        className="relative flex flex-col justify-center items-center h-fit w-full py-10"
      >
        <Box className={`flex flex-col justify-center items-center absolute h-full w-full inset-0 bg-fixed bg-no-repeat bg-cover bg-center`} style={{backgroundImage: `url(${isMobile ? BG_M : BG_D})`}}>
        </Box>
        <Box className={`flex flex-col justify-start items-center relative h-full w-full`}>
            <img src={BANNER} className="w-2/5 md:w-60 mb-10" />
            <a className="bit-widget-initializer"
    
                data-artist-name="id_320064"
                data-background-color="rgba(255,4,4,0)"
                data-separator-color="#FEF9D7"
                data-text-color="rgba(254,249,215,1)"
                data-font="American Typewriter"
                data-auto-style="true"
                data-button-label-capitalization="capitalize"
                data-header-capitalization=""
                data-location-capitalization="capitalize"
                data-venue-capitalization="capitalize"
                data-display-local-dates="true"
                data-local-dates-position="aboveUpcoming"
                data-display-past-dates="true"
                data-display-details="true"
                data-display-lineup=""
                data-display-start-time=""
                data-social-share-icon="true"
                data-display-limit="all"
                data-date-format="MMM. D, YYYY"
                data-date-orientation="horizontal"
                data-date-border-color="#9B9B9B"
                data-date-border-width="1px"
                data-date-capitalization=""
                data-date-border-radius="10px"
                data-event-ticket-cta-size="medium"
                data-event-custom-ticket-text=""
                data-event-ticket-text="TICKETS"
                data-event-ticket-icon=""
                data-event-ticket-cta-text-color="#000000"
                data-event-ticket-cta-bg-color="#9B9B9B"
                data-event-ticket-cta-border-color="#9B9B9B"
                data-event-ticket-cta-border-width="0px"
                data-event-ticket-cta-border-radius="4px"
                data-sold-out-button-text-color="#000000"
                data-sold-out-button-background-color="#9B9B9B"
                data-sold-out-button-border-color="#9B9B9B"
                data-sold-out-button-clickable="true"
                data-event-rsvp-position="left"
                data-event-rsvp-cta-size="medium"
                data-event-rsvp-only-show-icon=""
                data-event-rsvp-text="REMIND ME"
                data-event-rsvp-icon=""
                data-event-rsvp-cta-text-color="#9B9B9B"
                data-event-rsvp-cta-bg-color="#FEF9D7"
                data-event-rsvp-cta-border-color="#9B9B9B"
                data-event-rsvp-cta-border-width="1px"
                data-event-rsvp-cta-border-radius="4px"
                data-follow-section-position="top"
                data-follow-section-alignment="center"
                data-follow-section-header-text="Get updates on new shows, new music, and more."
                data-follow-section-cta-size="medium"
                data-follow-section-cta-text="FOLLOW"
                data-follow-section-cta-icon="true"
                data-follow-section-cta-text-color="#FEF9D7"
                data-follow-section-cta-bg-color="#9B9B9B"
                data-follow-section-cta-border-color="#9B9B9B"
                data-follow-section-cta-border-width="0px"
                data-follow-section-cta-border-radius="4px"
                data-play-my-city-position="bottom"
                data-play-my-city-alignment="Center"
                data-play-my-city-header-text="Don’t see a show near you?"
                data-play-my-city-cta-size="medium"
                data-play-my-city-cta-text="REQUEST A SHOW"
                data-play-my-city-cta-icon="true"
                data-play-my-city-cta-text-color="#FEF9D7"
                data-play-my-city-cta-bg-color="#9B9B9B"
                data-play-my-city-cta-border-color="#9B9B9B"
                data-play-my-city-cta-border-width="0px"
                data-play-my-city-cta-border-radius="4px"
                data-optin-font=""
                data-optin-text-color=""
                data-optin-bg-color=""
                data-optin-cta-text-color=""
                data-optin-cta-bg-color=""
                data-optin-cta-border-width=""
                data-optin-cta-border-radius=""
                data-optin-cta-border-color=""
                data-language="en"
                data-layout-breakpoint="900"
                data-app-id=""
                data-affil-code=""
                data-bit-logo-position="bottomRight"
                data-bit-logo-color="#CCCCCC"
            ></a>
        </Box>
      </Box>
    );
  };
  
  export default Tour;
  