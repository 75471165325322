import {
    Box,
  } from "../../../components/StyledComponent";
  import { useEffect, useRef, useState } from "react";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { RoutePattern } from "../../../routes/RoutePattern";
  import styled from "styled-components";
  import Button from "../../../components/Form/Button";
  import { isMobile } from "../../../constants";
  
  import BG_D from "../../../assets/images/merch_desktop.jpg";
  import BG_M from "../../../assets/images/merch_mobile.jpg";
  import BANNER from "../../../assets/images/merch_banner_61323.png";
  import HEX from "../../../assets/images/hex.svg";

  import MERCH_D from "../../../assets/images/Dorothy merch desktop.png";
  import MERCH_M from "../../../assets/images/Dorothy merch mobile.png";

  const Merch = () => {
    return (
      <Box
        className="relative flex flex-col justify-center items-center h-fit w-full py-10"
      >
        {/* <Box className={`flex flex-col justify-center items-center absolute h-full w-full inset-0 bg-fixed bg-no-repeat bg-cover bg-center opacity-75`} style={{backgroundImage: `url(${isMobile ? BG_M : BG_D})`}}>
        </Box> */}
        <Box className={`flex flex-col justify-center items-center relative h-full w-full`}>
            <Box className="relative flex flex-col justify-center items-center my-8 md:my-14">
                {/* <img src={BANNER} className="w-auto" /> */}
                <Box className="text-[#FEF9D7] text-8xl md:text-9xl mb-10 font-blackFox">
                  merch
                </Box>
                {/* <Box className="text-[#FEF9D7] text-5xl">
                  coming soon
                </Box> */}
                <Box className="w-2/3 mb-10">
                  <img src={isMobile ? MERCH_M : MERCH_D} className="" />
                </Box>
                <button onClick={() => window.open('https://dorothyonfire.store/')} className="border border-[#FEF9D7] rounded-md w-fit m-auto px-5 py-2 bg-black/50 text-[#FEF9D7] text-2xl md:text-3xl font-blackFox">shop now</button>
            </Box>
        </Box>
        <img src={HEX} className="z-10 absolute w-24 md:w-32 bottom-0 -mb-12 md:-mb-16" />
      </Box>
    );
  };
  
  export default Merch;