import { leftMenu, rightMenu } from "./data";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";
import LOGO_IMG from "../../assets/images/Dorothy Name cream.png";
// import LOGO_IMG2 from "../../assets/images/logo-purple-to-black_1-single-loop.gif";
import NEWLOGO from "../../assets/images/newlogo5.png";
import useGlobalService from "../../app/Hooks/useGlobalService";
import { isMobile } from "../../constants";

const Container = styled.div`
  z-index: 20;
  background-color: rgba(179, 0, 27, 0.8);

  @media (min-width: 768px) {
    background-color: rgba(179, 0, 27, 0.8);
  }
`;

export default function Navigation() {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { setIsScrollableVideoEnded } = useGlobalService();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
  }, []);

  const menuItemsLeft = leftMenu.map((item, idx) =>
    // this statement is application only if we're at home
    item.isRoute ? (
      <ScrollLink
        key={idx}
        to={item.name} // id of the section
        smooth
        duration={1000}
        spy={true}
        onClick={() => {
          setIsActive(false);
          setIsScrollableVideoEnded(true);
          if (location.pathname !== '/') {
            navigate('/#' + item.name);
            setTimeout(() => {
              window.scrollTo({ top: document.getElementById(item.name)?.offsetTop, behavior: 'smooth', });
            }, 100);
            window.history.pushState("", document.title, window.location.pathname + window.location.search);
          }
        }}
        className={`mx-2 md:mx-8 my-3 md:my-0 cursor-pointer text-[#FEF9D7] text-shadow text-md md:text-lg tracking-[0.3em] select-none ${item.hideMobile && isMobile ? "hidden" : ""}`}
      >
        {item.name}
      </ScrollLink>
    ) : (
      <a
        key={idx}
        href={item.to} // route
        onClick={() => setIsActive(false)}
        className={`mx-5 md:mx-8 my-3 md:my-0 text-[#FEF9D7] text-shadow text-md md:text-lg tracking-[0.3em] select-none ${item.hideMobile && isMobile ? "hidden" : ""}`}
        target={item.linkOut ? "_blank" : "_self"}
      >
        {item.name}
      </a>
    )
  );

  const menuItemsRight = rightMenu.map((item, idx) =>
    // this statement is application only if we're at home
    item.isRoute ? (
      <ScrollLink
        key={idx}
        to={item.name} // id of the section
        smooth
        duration={1000}
        spy={true}
        onClick={() => {
          setIsActive(false);
          setIsScrollableVideoEnded(true);
          if (location.pathname !== '/') {
            navigate('/#' + item.name);
            setTimeout(() => {
              window.scrollTo({ top: document.getElementById(item.name)?.offsetTop, behavior: 'smooth', });
            }, 100);
            window.history.pushState("", document.title, window.location.pathname + window.location.search);
          }
        }}
        className={`mx-2 md:mx-8 my-3 md:my-0 text-shadow cursor-pointer text-[#FEF9D7] text-shadow text-md md:text-lg tracking-[0.3em] select-none ${item.hideMobile && isMobile ? "hidden" : ""}`}
      >
        {item.name}
      </ScrollLink>
    ) : (
      <a
        key={idx}
        href={item.to} // route
        onClick={() => setIsActive(false)}
        className={`mx-5 md:mx-8 my-3 md:my-0 text-[#FEF9D7] text-shadow text-md md:text-lg tracking-[0.3em] select-none ${item.hideMobile && isMobile ? "hidden" : ""}`}
        target={item.linkOut ? "_blank" : "_self"}
      >
        {item.name}
      </a>
    )
  );

  return (
    <Container className="mx-auto flex flex-col justify-center items-center text-white px-5 fixed top-0 left-0 w-full py-2 lg:px-24">
      <div className="w-full flex justify-center items-center">
        {/* desktop navigation */}
        <div className="uppercase justify-between items-center flex-row hidden md:flex font-bebas ml-36">
          {menuItemsLeft}
          <ScrollLink
            to="home"
            smooth
            duration={500}
          >
            <img src={LOGO_IMG} alt="logo" className="w-10 md:w-24 mx-5 cursor-pointer" />
          </ScrollLink>
          {menuItemsRight}
        </div>
        {/* tablet navigation */}
        <ScrollLink
          to="home" // id of the section
          smooth
          duration={500}
          spy={true}
          className="text-shadow text-center cursor-pointer font-extrabold text-shadow text-2xl md:text-5xl flex flex-row justify-center items-center m-auto md:hidden"
        >
          <img src={LOGO_IMG} alt="logo" className="w-24" />
        </ScrollLink>
        <div
          className={`uppercase justify-center items-center flex-col flex fixed top-0 transition-all w-full h-screen bg-[rgba(56,56,56,0.9)] md:hidden
            ${isActive ? "right-0" : "-right-full"}
        `}
        >
          <img src={LOGO_IMG} className="mx-auto mb-10 w-40" />
          <a
            href="https://dorothy-on-fire.myshopify.com/"
            className={`mx-5 md:mx-8 mt-4 mb-5 md:my-0 text-[#FEF9D7] text-shadow text-md tracking-[0.3em]`}
            target="_blank"
          >
            get merch
          </a>
          {menuItemsLeft}
          {menuItemsRight}
        </div>
        {/* burger */}
        <div
          className="absolute flex flex-col right-8 z-50 cursor-pointer md:hidden"
          aria-label="button"
          onClick={handleClick}
        >
          <div
            className={`w-10 h-1 md:h-1 my-1 rounded-sm bg-[#FEF9D7] ${
              isActive ? "rotate-45" : "rotate-0"
            } transition-all`}
            // style={{backgroundColor: 'rgba(130,85,131,1)'}}
          />
          <div
            className={`w-10 h-1 md:h-1 my-1 rounded-sm bg-[#FEF9D7] ${
              isActive ? "hidden" : "block"
            }`}
            // style={{backgroundColor: 'rgba(130,85,131,1)'}}
          />
          <div
            className={`w-10 h-1 md:h-1 my-1 rounded-sm bg-[#FEF9D7] ${
              isActive ? "-rotate-45 mt-1 absolute" : "rotate-0"
            } transition-all`}
            // style={{backgroundColor: 'rgba(130,85,131,1)'}}
          />
        </div>
      </div>
    </Container>
  );
}
