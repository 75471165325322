import {
    Box,
} from "../../../components/StyledComponent";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RoutePattern } from "../../../routes/RoutePattern";
import styled from "styled-components";
import Button from "../../../components/Form/Button";
import { isMobile } from "../../../constants";
import $ from "jquery";
import Swal from "sweetalert2";

import BG_D from "../../../assets/images/booking-desktop.png";
import BG_M from "../../../assets/images/booking-mobile.png";
import BOOKING from "../../../assets/images/booking2.png";
import INQUIRIES from "../../../assets/images/general inquiries2.png";
import SUBMIT from "../../../assets/images/submit.png";
  
const Contact = () => {
    const nameRef: any = useRef(null);
    const emailRef: any = useRef(null);
    const phoneRef: any = useRef(null);
    const messageRef: any = useRef(null);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    
    var notValid = 0;

    const submitInq = () => {
        const nameDiv = nameRef.current as HTMLInputElement;
        const emailDiv = emailRef.current as HTMLInputElement;
        const phoneDiv = phoneRef.current as HTMLInputElement;
        const messageDiv = messageRef.current as HTMLInputElement;

        notValid = 0;
        const name = nameDiv.value;
        const phone = phoneDiv.value;
        const email = emailDiv.value;
        const message = messageDiv.value;

        if (name.length < 1) {
            $(nameDiv).addClass('input-error');
            notValid++;
        }
        if (phone.length < 1) {
            $(phoneDiv).addClass('input-error');
            notValid++;
        }
        if (email.length < 1) {
            $(emailDiv).addClass('input-error');
            notValid++;
        }
        if (message.length < 1) {
            $(messageDiv).addClass('input-error');
            notValid++;
        }
        
        if (!validateEmail(email)) {
            $(emailDiv).addClass('input-error');
            notValid++;
        }

        if (!telephoneCheck(phone)) {
            $(phoneDiv).addClass('input-error');
            notValid++;
        }

        if (notValid < 1) {
            Swal.fire({
                title: 'SUBMITTING DATA'
            });
            Swal.showLoading();

            const formData = new FormData();
            formData.append('Full Name', name);
            formData.append('Email', email);
            formData.append('Phone', phone);
            formData.append('Message', message);
            fetch('https://script.google.com/macros/s/AKfycbxJhnxpL6co8NLS592FJ1qEDdOerPWEYrNPwrDTicGITrxwT0j7En2XZ-GWbfiN_7eu/exec', {
                method: 'POST',
                mode: 'cors',
                body: formData
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }

                Swal.fire({
                    title: 'SUCCESS',
                    text: 'Thank you for submitting!',
                    icon: 'success',
                    allowEscapeKey: false,
                    confirmButtonColor: '#000000',
                }).then((result) => {
                    $(nameDiv).val('');
                    $(emailDiv).val('');
                    $(phoneDiv).val('');
                    $(messageDiv).val('');
                });
                return false;
            })
        }
    }

    const handleChange = (e: any) => {
        var thisVal = e.target.value;
        // console.log(thisVal);
        if (thisVal !== "") {
            $(e.target).removeClass('input-error');
            notValid = 0;
        }
    }

    const validateEmail = (email: any) => {
        return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const telephoneCheck = (str: any) => {
        var isphone = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g.test(str);
        return isphone;
    }

    return (
        <Box
            id={RoutePattern.CONTACT}
            className="relative flex flex-col justify-center items-center min-h-[75vh] w-full"
        >
            <Box className={`flex flex-col justify-center items-center absolute h-full w-full inset-0 bg-fixed bg-no-repeat bg-cover bg-center opacity-25`} style={{backgroundImage: `url(${isMobile ? BG_M : BG_D})`}}>
            </Box>
            <Box className={`flex flex-col md:flex-row justify-center items-center relative h-full w-3/4`}>
                <Box className="relative flex flex-col justify-center items-center mt-20 mb-0 md:my-10 md:my-14 w-4/5 md:w-1/2">
                    <img src={BOOKING} className="h-20" />
                    <p className="font-cabin leading-normal tracking-wide text-[#FEF9D7] text-sm">SCOTT SOKOL</p>
                    <p className="font-cabin leading-normal tracking-wide text-[#FEF9D7] text-sm">PINNACLE ENTERTAINMENT</p>
                    <a className="font-cabin leading-normal tracking-wide text-[#FEF9D7] text-sm md:mb-32" href="mailto:scott@pinnacleentertainmentinc.com">scott@pinnacleentertainmentinc.com</a>
                </Box>
                <Box className="relative flex flex-col justify-center items-center mb-20 mt-10 md:my-10 md:my-14 w-4/5 md:w-1/2">
                    <img src={INQUIRIES} className="h-36 -mb-5" />
                    <Box className="flex flex-col w-72">
                        <input id="fullname" ref={nameRef} onChange={handleChange} type="text" aria-autocomplete="none" autoComplete="off" autoCorrect="off" placeholder="FULL NAME" className="check-input bg-transparent font-cabin text-[#FEF9D7] border border-[#FEF9D7] px-2 py-1 my-2 w-full" />
                        <input id="email" ref={emailRef} onChange={handleChange} type="email" aria-autocomplete="none" autoComplete="off" autoCorrect="off" placeholder="EMAIL" className="check-input bg-transparent font-cabin text-[#FEF9D7] border border-[#FEF9D7] px-2 py-1 my-2 w-full" />
                        <input id="phone" ref={phoneRef} onChange={handleChange} type="phone" aria-autocomplete="none" autoComplete="off" autoCorrect="off" placeholder="PHONE" className="check-input bg-transparent font-cabin text-[#FEF9D7] border border-[#FEF9D7] px-2 py-1 my-2 w-full" />
                        <textarea id="message" ref={messageRef} onChange={handleChange} aria-autocomplete="none" autoComplete="off" autoCorrect="off" placeholder="TYPE YOUR MESSAGE HERE" className="check-input bg-transparent font-cabin text-[#FEF9D7] border border-[#FEF9D7] px-2 py-1 my-2 w-full h-32 resize-none"></textarea>
                        <button id="submit_inq" onClick={submitInq} className="submit_inq border border-[#FEF9D7] rounded-md w-fit m-auto px-5 py-2 bg-black/50 text-[#FEF9D7] text-2xl md:text-3xl font-blackFox">submit</button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Contact;