import {
    Box,
  } from "../../../components/StyledComponent";
  import { useEffect, useRef, useState } from "react";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { RoutePattern } from "../../../routes/RoutePattern";
  import styled from "styled-components";
  import Button from "../../../components/Form/Button";
  import { isMobile } from "../../../constants";
  
  import IMG1 from "../../../assets/images/IG 1.jpg";
  import IMG2 from "../../../assets/images/IG 2.jpg";
  import IMG3 from "../../../assets/images/IG 3.png";
  import IMG4 from "../../../assets/images/IG 4.png";
  import IMG5 from "../../../assets/images/IG 5.jpg";
  
  const Instagram = () => {
    return (
      <Box
        id={RoutePattern.INSTAGRAM}
        className="relative flex flex-col justify-center items-center h-auto w-full bg-[rgba(30,30,30,1)]"
      >
        <Box className="relative flex justify-center items-center md:py-3">
            <img src={IMG1} className="w-40 md:w-64" />
            <img src={IMG2} className="w-40 md:w-64" />
            <img src={IMG3} className="w-40 md:w-64" />
            <img src={IMG4} className="w-40 md:w-64 hidden md:block" />
            <img src={IMG5} className="w-40 md:w-64 hidden md:block" />
        </Box>
        <Box className="absolute md:bg-[rgba(30,30,30,0.75)] font-cabin md:font-bebas px-12 py-5 text-[#FEF9D7] text-md md:text-xl tracking-[0.15em] md:tracking-[0.25em] cursor-pointer" onClick={() => window.open('https://www.instagram.com/dorothy/')}>
            FOLLOW ME ON INSTAGRAM
        </Box>
      </Box>
    );
  };
  
  export default Instagram;