import {
    Box,
  } from "../../../components/StyledComponent";
  import { useEffect, useRef, useState } from "react";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { RoutePattern } from "../../../routes/RoutePattern";
  import styled from "styled-components";
  import Button from "../../../components/Form/Button";
  import { isMobile } from "../../../constants";
  
  import BG_D from "../../../assets/images/music-desktop.png";
  import BG_M from "../../../assets/images/music-mobile.png";
  import BANNER from "../../../assets/images/music2.png";
  import LISTENON from "../../../assets/images/listen on.png";
  
  const Music = () => {
    return (
      <Box
        id={RoutePattern.MUSIC}
        className="relative flex flex-col justify-center items-center h-screen w-full"
      >
        <Box className={`flex flex-col justify-center items-center absolute h-full w-full inset-0 bg-fixed bg-no-repeat bg-cover bg-center opacity-50`} style={{backgroundImage: `url(${isMobile ? BG_M : BG_D})`}}>
        </Box>
        <Box className="relative flex flex-col justify-center items-center">
            <img src={BANNER} className="w-44 md:w-[15rem] mb-10" />
            <img src={LISTENON} className="w-36 md:w-56 -mt-12 md:-mt-14 mb-5 md:mb-0" />
            <Box className="flex flex-col md:flex-row text-[#FEF9D7] text-4xl md:text-3xl font-semibold text-center">
                <h2 className="m-5 md:my-0 cursor-pointer [text-shadow:_1px_1px_0_rgb(0_0_0_/_70%)]" onClick={() => window.open('https://open.spotify.com/artist/6IOvhXyk5edbA2DVaeP9Up')}>SPOTIFY</h2> <span className="hidden md:block">|</span> <h2 className="m-5 md:my-0 cursor-pointer [text-shadow:_1px_1px_0_rgb(0_0_0_/_70%)]" onClick={() => window.open('https://music.apple.com/us/artist/dorothy/952845940')}>APPLE</h2> <span className="hidden md:block">|</span> <h2 className="m-5 md:my-0 cursor-pointer [text-shadow:_1px_1px_0_rgb(0_0_0_/_70%)]" onClick={() => window.open('https://tidal.com/browse/artist/3541678')}>TIDAL</h2> <span className="hidden md:block">|</span> <h2 className="m-5 md:my-0 cursor-pointer [text-shadow:_1px_1px_0_rgb(0_0_0_/_70%)]" onClick={() => window.open('https://www.amazon.com/Dorothy/e/B001LHMM2K/ref=dp_byline_cont_music_1')}>AMAZON</h2>
            </Box>
        </Box>
      </Box>
    );
  };
  
  export default Music;